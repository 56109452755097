import React from "react";
import gql from "graphql-tag";
import { Query } from "@apollo/react-components";

import { setConfiguration } from "react-grid-system";
import "./App.css";
import Homepage from "./components/Homepage";

setConfiguration({
  defaultScreenClass: "xl",
  gridColumns: 0,
  breakpoints: [576, 768, 992, 1200],
  containerWidths: ["100%"], // 1140
  gutterWidth: 0,
  gridColumns: 12,
});

const GRAGGER_DATA = gql`
  {
    entries(site: "gragger_de", section: homepage) {
      ... on Homepage {
        components {
          title
          ... on Hero {
            type {
              name
            }
            image {
              url
            }
            headline {
              content
            }
          }
          ... on Imprint {
            title
            copytext {
              content
            }
          }
          ... on Privacy {
            title
            copytext {
              content
            }
          }
          ... on Simpleblock {
            type {
              name
            }
            copytext {
              content
            }
          }
          ... on Contentblock {
            type {
              name
            }
            headline {
              content
            }
            copytext {
              content
            }
          }
          ... on Simpleblockimage {
            type {
              name
            }
            copytext {
              content
            }
            image {
              url
            }
          }
          ... on Subhero {
            type {
              name
            }
            headline {
              content
            }
            image {
              url
            }
          }
          ... on Contact {
            type {
              name
            }
            headline {
              content
            }
            copytext {
              content
            }
            shops {
              ... on ShopsSingleshop {
                shopname
                shopadress {
                  content
                }
                shopmapurl
                shopmapimg {
                  url
                }
              }
            }
            extratext {
              content
            }
            image {
              url
            }
          }
        }
      }
    }
  }
`;

const GRAGGER_DATA_EN = gql`
  {
    entries(site: "gragger_en", section: homepage) {
      ... on Homepage {
        components {
          title
          ... on Hero {
            type {
              name
            }
            image {
              url
            }
            headline {
              content
            }
          }
          ... on Imprint {
            title
            copytext {
              content
            }
          }
          ... on Privacy {
            title
            copytext {
              content
            }
          }
          ... on Simpleblock {
            type {
              name
            }
            copytext {
              content
            }
          }
          ... on Contentblock {
            type {
              name
            }
            headline {
              content
            }
            copytext {
              content
            }
          }
          ... on Simpleblockimage {
            type {
              name
            }
            copytext {
              content
            }
            image {
              url
            }
          }
          ... on Subhero {
            type {
              name
            }
            headline {
              content
            }
            image {
              url
            }
          }
          ... on Contact {
            type {
              name
            }
            headline {
              content
            }
            copytext {
              content
            }
            shops {
              ... on ShopsSingleshop {
                shopname
                shopadress {
                  content
                }
                shopmapurl
                shopmapimg {
                  url
                }
              }
            }
            extratext {
              content
            }
            image {
              url
            }
          }
        }
      }
    }
  }
`;

class App extends React.Component {
  state = {
    language: "de",
  };

  switchLanguage = (lang) => {
    this.setState({
      language: lang,
    });
  };

  render() {
    let cmsdata = GRAGGER_DATA;

    console.log("cmsdata: ", cmsdata);

    if (this.state.language === "en") {
      cmsdata = GRAGGER_DATA_EN;
    }

    return (
      <>
        <Query query={cmsdata} fetchPolicy="no-cache">
          {({ loading, error, data }) => {
            if (loading) return null;
            if (error) return `Error! ${error}`;
            return (
              <Homepage
                onLangClick={this.switchLanguage.bind(this)}
                data={data}
                language={this.state.language}
              />
            );
          }}
        </Query>
      </>
    );
  }
}

export default App;
