import React from "react";
import styled from "styled-components";

import Fade from "react-reveal/Fade";
const Wrapper = styled.div`
  img {
    width: 100%;
    height: auto;
  }
`;

class ImageBlock extends React.Component {
  render() {
    const image = process.env.REACT_APP_IMG_URL + this.props.data.image[0].url;

    return (
      <Fade bottom distance={"100px"} duration={600} delay={300}>
        <Wrapper>
          <img src={image} alt="GRAGGER"></img>
        </Wrapper>
      </Fade>
    );
  }
}

export default ImageBlock;
