import React from "react";
import { HamburgerSpin } from "react-animated-burgers";
import Fade from "react-reveal/Fade";
import styled from "styled-components";

const Main = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100px;
  max-width: 1920px;
  margin: 0 auto;

  display: none;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    display: block;
  }
`;

const Base = styled.div`
  position: absolute;
  top: 20px;
  right: 78px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    right: 20px;
  }

  span {
    height: 3px;
    color: #ff0000;
  }
  transition: top ease 0.4s;
  &.isactive {
    top: 20px;
  }
`;

const Wrapper = styled.div`
  margin-top: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 3.3rem;
    line-height: 6.3rem;
  }
`;

const Btn = styled.div`
  cursor: pointer;
`;

const Sub = styled.div`
  margin-top: 75px;

  p,
  a {
    color: #000;
    line-height: 2.8rem;

    &.fx {
      color: #ba8f5c;
    }

    &.active {
      color: #ba8f5c;
    }

    font-size: 1.6rem;
    line-height: 3rem;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 100px;
  left: 0px;
  bottom: 0px;
  background-color: #fff;
  width: 100%;

  opacity: 0;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
  }

  &.hide {
    pointer-events: none;
    animation: fadeout ease-in 0.6s 1 normal forwards;
  }

  &.show {
    pointer-events: auto;
    animation: fadein ease-out 0.6s 1 normal forwards;
  }

  @keyframes fadeout {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

class Menu extends React.Component {
  state = {
    isActive: null,
  };

  toggleButton = () => {
    this.setState({
      isActive: !this.state.isActive,
    });

    this.props.onOpen();
  };

  handleClick = (index) => {
    this.toggleButton();
    this.props.onClick(index);
  };

  langClick = (index) => {
    this.toggleButton();
    this.props.onLangClick(index);
  };

  render() {
    const { status, language } = this.props;

    let de = "deutsch";
    let en = "englisch";

    let linkA = "Brot";
    let linkB = "Gragger";
    let linkC = "Läden";

    let copy = "Folgen Sie uns:";

    if (language === "en") {
      de = "german";
      en = "english";

      linkA = "Bread";
      linkB = "Gragger";
      linkC = "Shops";

      copy = "Follow us:";
    }

    return (
      <Main>
        <Overlay
          className={`overlay ${
            this.state.isActive === true ? "show" : "hide"
          }`}>
          <Wrapper>
            <Fade
              bottom
              distance={"100px"}
              duration={600}
              delay={100}
              when={this.state.isActive}>
              <Btn onClick={(e) => this.handleClick("brot", e)}>
                <h1>{linkA}</h1>
              </Btn>
              <Btn onClick={(e) => this.handleClick("gragger", e)}>
                <h1>{linkB}</h1>
              </Btn>
              <Btn onClick={(e) => this.handleClick("laeden", e)}>
                <h1>{linkC}</h1>
              </Btn>

              <Sub>
                <Btn>
                  <p
                    onClick={this.langClick.bind(this, "de")}
                    className={` ${language === "de" ? "active" : ""}`}>
                    {de}
                  </p>
                </Btn>
                <Btn>
                  <p
                    onClick={this.langClick.bind(this, "en")}
                    className={` ${language === "en" ? "active" : ""}`}>
                    {en}
                  </p>
                </Btn>
              </Sub>

              <Sub>
                <p>{copy}</p>
                <Btn>
                  <a
                    className="fx"
                    href="https://www.instagram.com/graggerbrot/"
                    target="_blank"
                    rel="noopener noreferrer">
                    Instagram
                  </a>
                </Btn>
                <Btn>
                  <a
                    className="fx"
                    href="https://www.facebook.com/"
                    target="_blank"
                    rel="noopener noreferrer">
                    Facebook
                  </a>
                </Btn>
              </Sub>
            </Fade>
          </Wrapper>
        </Overlay>

        <Base className={` ${this.state.isActive === true ? "isactive" : ""}`}>
          <HamburgerSpin
            buttonWidth={32}
            isActive={this.state.isActive}
            toggleButton={this.toggleButton}
          />
        </Base>
      </Main>
    );
  }
}

export default Menu;
