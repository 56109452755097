import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding-bottom: 130px;
  padding-left: 170px;
  padding-right: 100px;
  padding-top: 70px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding-left: 0px;
    padding-right: 0px;
  }

  h2,
  p {
    color: #000;
  }

  h2 {
    font-family: "Harbour-Medium";
    font-size: 4.3rem;
    line-height: 3.4rem;
    padding-bottom: 70px;
  }

  p {
    font-size: 1.9rem;
    line-height: 3.3rem;
  }
`;

class Imprint extends React.Component {
  render() {
    const copytext = this.props.data.copytext.content;

    return (
      <Wrapper>
        <p dangerouslySetInnerHTML={{ __html: copytext }} />
      </Wrapper>
    );
  }
}

export default Imprint;
