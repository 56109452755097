import React from "react";
import styled from "styled-components";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const Wrapper = styled.div`
  padding-bottom: 50px;

  .fx {
    &:hover {
      /*transform: translatey(-4px);*/
    }
  }
`;

const Grid = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  align-items: center;

  &.mobile {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    grid-template-columns: 100%;
    grid-template-rows: auto;

    &.mobile {
      display: block;
    }
    &.desktop {
      display: none;
    }
  }
`;

const GridItem = styled.div`
  text-align: right;
  align-self: end;

  &.left {
    justify-self: start;
  }

  &.right {
  }

  .imp {
    margin-right: 80px;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    text-align: left;
    align-self: start;
    display: flex;
    flex-direction: column;

    &.top,
    &.center {
      padding-bottom: 30px;
    }

    a {
      padding-bottom: 4px;
    }

    p {
      color: #000;
    }
  }
`;

class Footer extends React.Component {
  render() {
    let imp = "Impressum";
    let dat = "Datenschutz";

    if (this.props.lang === "en") {
      imp = "Legal notice";
      dat = "Privacy policy";
    }

    return (
      <Wrapper>
        <Grid className="desktop">
          <GridItem className="left">
            <p>© Das ist Brot GmbH, 2020</p>
          </GridItem>
          <GridItem className="right">
            <Link className="imp fx" to="/impressum">
              {imp}
            </Link>
            <Link className="fx" to="/datenschutz">
              {dat}
            </Link>
          </GridItem>
        </Grid>

        <Grid className="mobile">
          <GridItem className="top">
            <Link className="imp fx" to="/impressum">
              {imp}
            </Link>
            <Link className="fx" to="/datenschutz">
              {dat}
            </Link>
          </GridItem>
          <GridItem className="center">
            <p>Folgen Sie uns:</p>
            <a
              className="fx"
              href="https://www.instagram.com/graggerbrot/"
              target="_blank"
              rel="noopener noreferrer">
              Instagram
            </a>
            <a
              className="fx"
              href="https://www.facebook.com/"
              target="_blank"
              rel="noopener noreferrer">
              Facebook
            </a>
          </GridItem>

          <GridItem className="bottom">
            <p>© Das ist Brot GmbH, 2020</p>
          </GridItem>
        </Grid>
      </Wrapper>
    );
  }
}

export default Footer;
