import React from "react";
import styled from "styled-components";

import Fade from "react-reveal/Fade";

import { Container, Row, Col } from "react-grid-system";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Wrapper = styled.div`
  position: relative;
  padding-bottom: 100px;
  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding-bottom: 75px;
  }
`;

const CopyWrapper = styled.div`
  margin-top: -264px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    margin-top: -66px;
  }
`;

const Headline = styled.div`
  h1,
  p {
    font-family: "Harbour-Medium";
    color: #000;
    font-size: 12.2rem;
    line-height: 14.4rem;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    h1,
    p {
      font-size: 6.2rem;
      line-height: 7.8rem;
    }
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;

  max-width: 1440px;

  img {
    width: 100%;
    height: auto;
  }
`;

class Hero extends React.Component {
  render() {
    const headline = this.props.data.headline.content;
    const image = process.env.REACT_APP_IMG_URL + this.props.data.image[0].url;

    return (
      <Fade duration={500} delay={500}>
        <Wrapper id="brot">
          <ImageWrapper>
            <Container fluid>
              <Row>
                <Col sm={2} md={2} lg={2} xl={2}></Col>
                <Col
                  xs={10}
                  sm={10}
                  md={10}
                  offset={{ xs: 2, sm: 2 }}
                  lg={10}
                  xl={10}>
                  <LazyLoadImage src={image} />
                </Col>
              </Row>
            </Container>
          </ImageWrapper>
          <CopyWrapper>
            <Container fluid>
              <Row>
                <Col sm={0} md={0} lg={1} xl={1}></Col>
                <Col sm={12} md={12} lg={11} xl={11}>
                  <Headline>
                    <h1 dangerouslySetInnerHTML={{ __html: headline }} />
                  </Headline>
                </Col>
              </Row>
            </Container>
          </CopyWrapper>
        </Wrapper>
      </Fade>
    );
  }
}

export default Hero;
