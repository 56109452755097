import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  margin-top: -170px;
  right: 58px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    display: none;
  }
`;

const Box = styled.div`
  position: absolute;
  top: 340px;
  left: 0px;
  width: 340px;
  height: 20px;

  transform: rotate(-90deg);
  transform-origin: top left;

  p,
  a {
    cursor: pointer;
    display: inline;
    font-size: 1.4rem;
    padding-right: 15px;

    :hover {
      color: #000;
    }
  }
`;

class BackTop extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  componentDidUpdate(prevProps) {
    //
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onCheckScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onCheckScroll, false);
  }

  onCheckScroll = () => {
    let pos = window.pageYOffset;

    if (pos > 300) {
      this.setState({
        show: true,
      });
    } else {
      this.setState({
        show: false,
      });
    }
  };

  buttonClick = (id) => {
    this.props.onButtonClick(id);
  };

  render() {
    let copy = "Zurück zum Anfang";
    if (this.props.lang === "en") {
      copy = "Back to top";
    }

    return (
      <Wrapper>
        <Fade
          bottom
          distance={"75px"}
          duration={900}
          delay={600}
          when={this.state.show}>
          <Box>
            <p onClick={this.buttonClick.bind(this, "brot")}>{copy}</p>
          </Box>
        </Fade>
      </Wrapper>
    );
  }
}

export default BackTop;
