import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  margin-top: -170px;
  left: 32px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    display: none;
  }
`;

const Box = styled.div`
  position: absolute;
  top: 340px;
  left: 0px;
  width: 340px;
  height: 20px;

  transform: rotate(-90deg);
  transform-origin: top left;

  p,
  a {
    display: inline;
    font-size: 1.4rem;
    padding-right: 15px;
  }
`;

class Social extends React.Component {
  render() {
    let copy = "Folgen Sie uns:";
    if (this.props.lang === "en") {
      copy = "Follow us:";
    }

    return (
      <Wrapper>
        <Fade bottom distance={"75px"} duration={900} delay={600}>
          <Box>
            <p>{copy}</p>
            <a
              className="fx"
              href="https://www.instagram.com/graggerbrot/"
              target="_blank"
              rel="noopener noreferrer">
              Instagram
            </a>
            <a
              className="fx"
              href="https://www.facebook.com/graggerbrot/"
              target="_blank"
              rel="noopener noreferrer">
              Facebook
            </a>
          </Box>
        </Fade>
      </Wrapper>
    );
  }
}

export default Social;
