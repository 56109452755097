import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";

import { setContext } from "apollo-link-context";

import { InMemoryCache } from "apollo-cache-inmemory";

import { ApolloProvider } from "@apollo/react-hooks";
import * as serviceWorker from "./serviceWorker";

import "./index.css";
import App from "./App";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_CRAFT_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = process.env.REACT_APP_CRAFT_TOKEN;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
