import React from "react";
import styled from "styled-components";
import ResizeObserver from "resize-observer-polyfill";
import { withRouter } from "react-router-dom";
import { gsap, Power4 } from "gsap";
import lethargy from "lethargy";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
/* eslint-disable import/first */
gsap.registerPlugin(ScrollToPlugin);

const Ani = styled.div`
  .viewport {
    margin: 0 0 0 0;
    width: 100vw;
    overflow-x: hidden;
    position: fixed;
  }

  .fake {
    pointer-events: none;
  }
`;

class AutoScroll extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeoutId: 0,
      timeoutRestart: 0,
      height: 0,
      actualPos: 0,
      autoPlay: false,
      dir: 1,
      lastPos: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.stopScrollFx();
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.onScroll();

    this.ro = new ResizeObserver((elements) => {
      for (let elem of elements) {
        const crx = elem.contentRect;
        this.setState({
          height: crx.height,
        });
      }
    });

    this.ro.observe(this.viewport);
    window.addEventListener("scroll", this.onScroll, false);

    if (window.location.pathname === "/") {
      let timeoutId = setTimeout(() => {
        this.startScrollFx();
      }, 2000);
      this.setState({ timeoutId: timeoutId });
    }
  }

  componentWillUnmount() {
    if (this.ro) {
      this.ro.disconnect();
    }
    clearTimeout(this.state.timeoutId);
    clearTimeout(this.state.loopTimeout);
    clearTimeout(this.state.timeoutRestart);
    window.removeEventListener("scroll", this.onScroll, false);
  }

  onScroll = () => {
    let pos = window.pageYOffset;
    let diff = 0;

    if (this.state.dir === "up") {
      let direction = "0";
      if (pos < this.state.lastPos) {
        diff = this.state.lastPos - pos;
        direction = "up";
      } else {
        direction = "down";
        diff = pos - this.state.lastPos;
      }

      direction = "down";
      this.setState({
        lastPos: pos,
        actualPos: pos,
        dir: direction,
      });
    } else {
      let direction = "0";
      if (pos < this.state.lastPos) {
        diff = this.state.lastPos - pos;
        direction = "up";
      } else {
        direction = "down";
        diff = pos - this.state.lastPos;
      }

      direction = "down";
      this.setState({
        lastPos: pos,
        actualPos: pos,
        dir: direction,
      });
    }

    gsap.to(this.viewport, {
      duration: 1,
      ease: Power4.easeOut,
      y: -pos,
    });

    if (diff > 2) {
      this.stopScrollFx();
    } else {
      if (this.state.autoPlay === false) {
        // this.checkRestart();
      }
    }
  };

  checkRestart = () => {
    let max = Math.round(this.state.height - window.innerHeight);

    if (this.state.actualPos >= 0 && this.state.actualPos < max) {
      if (this.state.actualPos === 0) {
        this.setState({
          dir: "down",
        });
      }
      this.startScrollFx();
    }
  };

  startScrollFx = () => {
    this.setState({ autoPlay: true });
    this.scrollFx();
  };

  scrollFx = () => {
    let max = Math.round(this.state.height - window.innerHeight);
    window.scrollTo(0, this.state.actualPos);

    if (this.state.dir === "up") {
      if (this.state.actualPos !== 0) {
        if (this.state.autoPlay === true) {
          if (window.location.pathname === "/") {
            this.setState({ actualPos: this.state.actualPos - 2 });
          }
          this.loopFx();
        } else {
          //
        }
      } else {
        this.stopScrollFx();
      }
    } else {
      if (this.state.actualPos < max) {
        if (this.state.autoPlay === true) {
          if (window.location.pathname === "/") {
            this.setState({ actualPos: this.state.actualPos + 2 });
          }
          this.loopFx();
        } else {
          //
        }
      } else {
        this.stopScrollFx();
      }
    }
  };

  loopFx = () => {
    clearTimeout(this.state.loopTimeout);
    let loopTimeout = setTimeout(() => {
      this.scrollFx();
    }, 50);
    this.setState({ loopTimeout: loopTimeout });
  };

  stopScrollFx = () => {
    clearTimeout(this.state.timeoutId);
    this.setState({ autoPlay: false });

    clearTimeout(this.state.timeoutRestart);
    let timeoutRestart = setTimeout(() => {
      this.checkRestart();
    }, 2000);
    this.setState({ timeoutRestart: timeoutRestart });
  };

  render() {
    return (
      <Ani>
        <div className="viewport" ref={(ref) => (this.viewport = ref)}>
          {this.props.children}
        </div>

        <div
          className="fake"
          ref={(ref) => (this.fake = ref)}
          style={{
            height: this.state.height,
          }}
        />
      </Ani>
    );
  }
}

export default withRouter(AutoScroll);
